import { CustomMarginType, DBOperationResult } from "../../types/types";
import { Logger } from "../../../../lib/logger/Logger";
import { postalCodeToCanton } from "../../lib/constants";

export const getUnderscoredString = (str: string) => {
	return str.replace(/\s/g, "_");
};

export function dateOlderThanSeconds(date: Date, seconds: number) {
	return date < new Date(Date.now() - seconds * 1000);
}

export function blobToBase64(blob: Blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			resolve(reader.result);
		};
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
}

// Convert Timestamp to Date
export function ConvertTimestampToDate(timestamp: string): string {
	const date = new Date(timestamp);
	return date.toLocaleDateString();
}

export const removeBracesAndBrackets = (input: string) => {
	return input.replace(/[{}[\]<>]/g, "");
};

/**
 * goBackOrForwardDays - Returns a date in ISO format (YYYY-MM-DDT00:00:00.000Z) for the given number of days in the past or future
 * @param days - number of days to go back or forward
 * @returns ISO date
 */
export function goBackOrForwardDays(days: number) {
	const date = new Date();
	date.setDate(date.getDate() + days);
	const utcDate = new Date(
		Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
	);
	const isoDate = utcDate.toISOString().split("T")[0] + "T00:00:00.000Z";
	return isoDate;
}

/**
 *
 * @param input - string in format "2023-09-07T20:15:41.913034+00:00" (supabase timestampz)
 */
export function getDayAndTime(input: string | null): string {
	if (!input) {
		return "";
	}

	// Create a new date object from the input
	const date = new Date(input);

	// Create a new date object for the current date
	const currentDate = new Date();

	// Calculate the difference in milliseconds between the two dates
	const timeDifference = currentDate.getTime() - date.getTime();

	// Convert the difference to hours
	const hoursDifference = timeDifference / (1000 * 60 * 60);

	// Get the day of the week in Zurich's time zone
	const day = new Intl.DateTimeFormat("de-DE", {
		weekday: "long",
		timeZone: "Europe/Zurich",
	}).format(date);

	// If the date is more than 7 days in the past, return the date
	if (hoursDifference > 7 * 24) {
		if (hoursDifference > 30 * 24) {
			return `${day} ${new Intl.DateTimeFormat("de-DE", {
				dateStyle: "short",
				timeZone: "Europe/Zurich",
			}).format(date)}`;
		} else {
			// vor X Tagen
			return `vor ${Math.floor(hoursDifference / 24)} Tagen`;
		}
	}

	// Get the time without seconds in Zurich's time zone
	const hours = new Intl.DateTimeFormat("de-DE", {
		hour: "2-digit",
		minute: "2-digit",
		hour12: false,
		timeZone: "Europe/Zurich",
	}).format(date);

	const formattedTime = hours;

	if (hoursDifference < 24 && !isYesterday(date)) {
		return `${formattedTime}`;
	}

	if (isYesterday(date)) {
		return `Gestern ${formattedTime}`;
	}

	return `${day} ${formattedTime}`;
}

/**
 * Checks if a given date is equal to "yesterday"
 *
 * @param inputDate The date you want to check
 */
function isYesterday(inputDate: Date): boolean {
	// Get the current date
	const currentDate = new Date();

	// Subtract one day to get yesterday's date
	currentDate.setDate(currentDate.getDate() - 1);

	// Compare the year, month, and date
	return (
		inputDate.getUTCFullYear() === currentDate.getUTCFullYear() &&
		inputDate.getUTCMonth() === currentDate.getUTCMonth() &&
		inputDate.getUTCDate() === currentDate.getUTCDate()
	);
}

export function formatDate(input: string | null | undefined): string {
	if (!input) {
		return "";
	}
	const date = new Date(input);
	return date.toLocaleDateString("de-CH");
}

export const undefinedHandler = (name: string): DBOperationResult => {
	const message = `Error: ${name} is undefined`;
	Logger.log(message);
	return { success: false, error: message, data: null, status: 500 };
};

/**
 * handleDatabaseOperation - Wraps all database operations in a try/catch block and returns an OperationResult with success, data, and error properties.
 * @param operation (supabase Promise)
 * @returns OperationResult
 * @example const { success, data, error } = await handleDatabaseOperation(supabase.from("users").select("*"));
 * If the operation has an error, success = false
 * If the operation has no data, success = true and data = null
 * If the operation has data, success = true and data = data
 */
export const handleDatabaseOperation = async (
	operation: any
): Promise<DBOperationResult> => {
	try {
		const res = await operation;
		Logger.log(
			...getOperationHandlerSignature(operation, "New operation"),
			res,
			getOperationLogInformation(operation)
		);
		if (res.error) {
			if (res.status === 409) {
				// TODO: There are other errors that might happen here, such as a foreign key not existing "Key is not present in table"
				Logger.warn(
					"Der Code wird bereits von einem bestehenden Eintrag verwendet."
				);
			}
			Logger.error(
				...getOperationHandlerSignature(operation, "Response error"),
				res,
				getOperationLogInformation(operation)
			);
			return {
				success: false,
				data: null,
				error: res.error,
				status: res.status,
			};
		}
		if (!res.data || res.data?.length === 0) {
			Logger.warn(
				...getOperationHandlerSignature(operation, "No data"),
				res,
				getOperationLogInformation(operation)
			);
			return {
				success: true,
				data: null,
				error: null,
				status: res.status,
			};
		}
		return {
			success: true,
			data: res.data,
			error: null,
			status: res.status,
		};
	} catch (error) {
		Logger.error(
			...getOperationHandlerSignature(operation, "Error"),
			error,
			getOperationLogInformation(operation)
		);
		return {
			success: false,
			error: `Error during DB operation: ${error}`,
			data: null,
			status: 500,
		};
	}
};

/**
 * Takes an operation input and retrieves selected information for logging
 */
const getOperationLogInformation = (operation: any) => {
	return {
		method: operation.method,
		path: operation?.url?.href,
		search: operation?.url?.search,
		body: operation.body,
	};
};

const getOperationHandlerSignature = (operation: any, message: string) => {
	return [
		`%cDB - ${message}: ${operation?.method} ${operation?.url?.pathname}`,
		"color: blue; background-color: lightgray;",
	];
};

/**
 * getTrueKeys - Returns an array of keys where the value is true
 * @param input - Object with keys and boolean values
 * @returns Array of keys where the value is true
 * @example const trueKeys = getTrueKeys({ key1: true, key2: false, key3: true }); // ["key1", "key3"]
 */
export const getTrueKeys = (input: { [key: string]: boolean }): string[] => {
	return Object.entries(input)
		.filter(([key, value]) => value) // Filters out entries where the value is not true
		.map(([key]) => key); // Returns only the keys
};

export const _getJobDocumentFilePath = (
	organizationId: string,
	clientId: number,
	jobId: number,
	jobDocumentId: number
) => {
	const path = `${organizationId}/${clientId}/${jobId}/${jobDocumentId}`;
	const bucketName = "job_files";
	return { path: path, bucketName: bucketName };
};

export const _getJobFilePath = (
	organizationId: string,
	clientId: number,
	jobId: number
) => {
	const path = `${organizationId}/${clientId}/${jobId}`;
	const bucketName = "job_document_files";
	return { path: path, bucketName: bucketName };
};

/**
 * Clean file name by replacing unsafe characters and consecutive whitespaces with underscore
 * @param filename The original file name
 * @returns Cleaned file name
 */
export function cleanFileName(filename: string): string {
	// Define the unsafe characters
	// eslint-disable-next-line
	const unsafeCharacters = /[\/\\:*?"<>|]/g;

	// Replace unsafe characters with an underscore
	let cleanedFilename = filename.replace(unsafeCharacters, "_");

	// Replace one or more consecutive whitespaces with a single underscore
	cleanedFilename = cleanedFilename.replace(/\s{2,}/g, "_");

	// Replace any remaining single whitespace characters with an underscore
	cleanedFilename = cleanedFilename.replace(/\s/g, "_");

	return cleanedFilename;
}

/**
 * getPdfMultiplier - Use IsPreviewContext to receive isPreview value, then use getPdfMultiplier to get the multiplier
 * @param {boolean} isPreview
 * @returns {number} multiplier - 0.75 for PDF export, 0.7 for preview
 */
export const getPdfMultiplier = (isPreview: boolean): number => {
	// For some reason what is 210mm for A4 comes out as 157.5mm when exporting, thus we apply a factor of 0.75
	// As an alternative, we could also apply scale 0.75 to the PDFExport component
	return isPreview ? 0.7 : 0.75;
};

/**
 * convertCustomMarginTypeToRem
 * @param {CustomMarginType} margin
 * @returns margin in rem
 * @example customMargin = convertCustomMarginTypeToRem("dense"); // "0.5rem"
 */
export const convertCustomMarginTypeToRem = (margin: CustomMarginType) => {
	return margin === "none"
		? "0rem"
		: margin === "dense"
			? "0.5rem"
			: "1.5rem";
};

/**
 * Helper function to create the secure patient name
 * @param patientId
 * @param firstName
 * @param lastName
 * @param isPrivacyOn
 */
export const createSecurePatientName = (
	patientId: string | null,
	firstName: string | null,
	lastName: string | null,
	isPrivacyOn: boolean
) => {
	if (isPrivacyOn) {
		return `${firstName?.charAt(0)} ${lastName?.charAt(0)}`;
	} else if (patientId && !firstName && !lastName) {
		return "Unbenannter Patient";
	} else if (!patientId && !firstName && !lastName) {
		return "...";
	} else {
		return `${firstName ?? ""} ${lastName ?? ""}`;
	}
};

export const getCantonFromPostalCode = (postalCode: number): string => {
	return postalCodeToCanton[postalCode] ?? "";
};

/**
 * Takes a function that it repeats every interval until it returns success or the timeout is reached
 * The result from the passed function will be returned once it returns success
 */
export const repeatFunctionEveryIntervalUntilTimeout = async ({
	interval = 1000,
	timeout = 10000,
	functionToCall,
	resolveOnSuccessAndData = false,
}: {
	interval?: number;
	timeout?: number;
	functionToCall: () => Promise<{
		success: boolean;
		data?: unknown;
		error?: unknown;
	}>;
	resolveOnSuccessAndData?: boolean;
}): Promise<{
	success: boolean;
	data?: unknown;
	error?: unknown;
}> => {
	const startTime = Date.now();
	while (Date.now() - startTime < timeout) {
		try {
			Logger.info("Polling...");
			const response = await functionToCall();
			if (
				response.success &&
				(resolveOnSuccessAndData === false || response.data)
			) {
				return response;
			}
		} catch (error) {
			Logger.error("Polling error", error);
			return {
				success: false,
				error: error,
			};
		}
		await new Promise((resolve) => setTimeout(resolve, interval));
	}
	return {
		success: false,
		error: "Timeout",
	};
};

export function removeStringTag(xml: string): string {
	return xml
		.replace(
			/<string[^>]*>([\s\S]*?)<\/string>/g,
			(match, innerContent) => {
				return innerContent
					.replace(/&lt;/g, "<")
					.replace(/&gt;/g, ">")
					.replace(/&amp;/g, "&")
					.trim();
			}
		)
		.trim();
}

export function containsException(input: string): boolean {
	return input.toLowerCase().includes("exception");
}
