import { Logger } from "@/lib/logger/Logger";
import { AxiosError } from "axios";
import { supabase } from "@/lib/supabase";
import { v4 as uuid } from "uuid";
import {
	PatientEntityType,
	SupabaseTableEnum,
} from "@/lib/supabase/supabaseTypes";
import { CompleteJobDocumentInformation } from "../actions/useExportActions";
import { formatDocumentPropsForApi, postDataToApi } from "./export-xml-helpers";
import { JobDocumentTypeEnum } from "../../pages/job-page/job-document/job-document.types";
import { useCentralStore } from "../../store/Central";
import { StorageBucketsEnum } from "../../types/enums";
import {
	containsException,
	removeStringTag,
} from "../../lib/utils/utils-functions";

export enum XmlEndpointsEnum {
	QUOTATION = "quotation",
	DELIVERY_NOTE = "delivery_note",
}

interface ExportXmlProps {
	documentProps: CompleteJobDocumentInformation;
	patient: PatientEntityType;
	endpoint: XmlEndpointsEnum;
	fileName?: string;
}

export const useExportXml = () => {
	const organization = useCentralStore((state) => state.organization);

	const exportXml = async ({
		documentProps,
		patient,
		endpoint,
		fileName,
	}: ExportXmlProps): Promise<{ success: boolean; error: string }> => {
		if (!organization) {
			Logger.error("Organization not found", { organization });
			return {
				success: false,
				error: "Organisation nicht gefunden",
			};
		}

		try {
			const { data, error } = formatDocumentPropsForApi({
				documentProps: {
					...documentProps,
					patient,
					organization,
				},
				documentType: documentProps.jobDocument.type as
					| JobDocumentTypeEnum.DeliveryNote
					| JobDocumentTypeEnum.Quotation,
			});
			if (!data) {
				return {
					success: false,
					error: error ?? "Error formatting document props",
				};
			}

			const rawXml = await postDataToApi({
				endpoint,
				data,
			});
			const xml = removeStringTag(rawXml.data);

			if (containsException(xml)) {
				// No need to show notification will be propagated
				return {
					success: false,
					error: "Der Server ist überlastet. Bitte melden Sie sich bei uns im Supportchat.",
				};
			}

			const { data: dbData, error: dbError } = await supabase
				.from(SupabaseTableEnum.FILES)
				.insert([
					{
						file_name: `${fileName ?? uuid()}.xml`,
						bucket_name: StorageBucketsEnum.V1,
						path_name: `${organization.id}/${uuid()}.xml`,
						job_document_id: documentProps.jobDocument.id,
						organization_id: organization.id,
					},
				])
				.select()
				.single();

			if (!dbData || dbError || !dbData["path_name"]) {
				Logger.error("Error saving file to database", { dbError });
				return {
					success: false,
					error: "Error saving file to database",
				};
			}

			const { data: storageData, error: storageError } =
				await supabase.storage
					.from(StorageBucketsEnum.V1)
					.upload(dbData.path_name, xml, {
						contentType: "application/xml",
					});

			Logger.info("File saved to database and storage", {
				storageData,
				storageError,
				dbData,
				dbError,
			});

			return {
				success: true,
				error: "",
			};
		} catch (e) {
			Logger.error(e);
			return {
				success: false,
				error:
					// Axios stores the error response in e.response.data as an object
					e instanceof AxiosError
						? (
								Object.values(e.response?.data.errors)[0] ??
								"Unknown error"
							).toString()
						: "Unknown error",
			};
		}
	};

	return {
		exportXml,
	};
};
